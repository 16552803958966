import PropTypes from 'prop-types';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';

const Wrapper = styled('div', {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
});

const SizeGuideWrapper = ({ children, contentPosition, marginTop }) => {
    const [css] = useStyletron();

    return (
        <Wrapper $style={{ justifyContent: contentPosition, marginTop }}>
            <div className={css({ width: ['100%', null, null, '80%'] })}>{children}</div>
        </Wrapper>
    );
};

SizeGuideWrapper.propTypes = {
    children: PropTypes.node,
    contentPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
};

export default SizeGuideWrapper;
